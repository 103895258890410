<template>
  <div class="text-center">
    <form class="form-signin">
      <h2>WGTS Donations</h2>
      <h3 class="mb-3 font-weight-normal">Please sign in</h3>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input
        type="email"
        id="inputEmail"
        v-model="data.body.email"
        class="form-control"
        placeholder="Email address"
        required
        autofocus
      />
      <label for="inputPassword" class="sr-only">Password</label>
      <div class="input-group">
        <input
          :type="type"
          id="inputPassword"
          v-model="data.body.password"
          class="form-control"
          placeholder="Password"
          required
        />
        <div class="input-group-append">
          <div class="input-group-text" @click="showPassword">
            <font-awesome-icon icon="eye" />
          </div>
        </div>
      </div>
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" v-model="data.rememberMe" />
          Remember me
        </label>
      </div>
      <FormError v-if="error" :error="error" classes="text-left" />
      <button
        @click.prevent="login()"
        class="btn btn-lg btn-primary btn-block"
        type="submit"
        :disabled="isLoading"
      >
        Sign in
        <font-awesome-icon v-if="isLoading" icon="sync" spin />
      </button>
    </form>
  </div>
</template>

<script>
import FormError from '../../components/forms/FormError';

export default {
  name: 'Login',
  components: { FormError },
  data() {
    return {
      context: 'login context',
      data: {
        body: {
          email: '',
          password: '',
        },
        rememberMe: false,
      },
      error: false,
      isLoading: true,
      type: 'password',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
  },
  created() {
    // console.log('Login created()', this.loggedIn);
    if (this.loggedIn) {
      this.$router.push({ name: 'dashboard' });
    }
    this.isLoading = false;
  },
  methods: {
    login() {
      this.error = false;
      this.isLoading = true;

      const payload = {
        email: this.data.body.email,
        password: this.data.body.password,
        rememberMe: this.data.rememberMe,
      };

      this.$store
        .dispatch('auth/login', payload)
        .then(() => {
          this.$router.push({ name: 'dashboard' });
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showPassword() {
      this.type = this.type === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style scoped lang="scss">
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;

  input[type='email'] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  input[type='password'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .input-group {
    margin-bottom: 10px;
  }
}
</style>
